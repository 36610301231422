import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http'
import { inject } from '@angular/core'
import { environment } from '@environments/environment'
import { OAuthStorage } from 'angular-oauth2-oidc'
import { fetchAuthSession } from 'aws-amplify/auth'
import { from, mergeMap } from 'rxjs'

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
	return from(getToken()).pipe(
		mergeMap((token) => {
			let activeRol: any = localStorage.getItem('activeRol') ?? ''
			if (activeRol) {
				activeRol = JSON.parse(activeRol)
			}

			if (req.url.includes(environment.urlServer)) {
				const headers: HttpHeaders = new HttpHeaders({
					Authorization: `Bearer ${token}`,
					rol: `${activeRol?.nombre}`,
				})
				const reqClone = req.clone({ headers })
				return next(reqClone)
			}

			return next(req)
		})
	)
}

// Obtiene el token de autenticación de OAuth o Amplify
const getToken = async (): Promise<string> => {
	const oauthStorage = inject(OAuthStorage)

	// Verificamos si el usuario inició sesión con OAuth
	if (oauthStorage.getItem('id_token')) {
		return oauthStorage.getItem('id_token') ?? ''
	}

	// Verificamos si el usuario inició sesión con Amplify
	const amplifyToken = (await fetchAuthSession()).tokens?.idToken?.toString()

	return amplifyToken ?? ''
}
