import { Component, OnDestroy } from '@angular/core'
import { NavigationStart, Router, RouterOutlet } from '@angular/router'
import { environment } from '@environments/environment'
import { SidebarService } from '@shared/services/sidebar.service'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet],
	template: `<span class="hidden">{{ env.versionDev + ' ' + env.env }}</span> <router-outlet></router-outlet>`,
})
export class AppComponent implements OnDestroy {
	env = environment
	subscription: Subscription
	browserRefresh = true

	constructor(
		readonly sidebarService: SidebarService,
		private router: Router
	) {
		this.subscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.browserRefresh = !router.navigated
				if (this.browserRefresh) {
					localStorage.setItem('refreshed', JSON.stringify(this.browserRefresh))
				} else {
					localStorage.removeItem('refreshed')
				}
			}
		})
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe()
	}
}
