import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { AuthService } from '@shared/services/auth/auth.service'
import { OAuthService } from '@shared/services/auth/oauth.service'

export const authGuard: CanActivateFn = async (): Promise<boolean> => {
	const authService = inject(AuthService)
	const oauthService = inject(OAuthService)
	const router = inject(Router)

	const isAuthenticatedByAuth: boolean = await authService.isAuthenticated()
	const isAuthenticatedByOAuth: boolean = await oauthService.isAuthenticated()

	// Solo permite el acceso a la pagina de inicio si esta autenticado por Auth o por OAuth
	if (isAuthenticatedByAuth || isAuthenticatedByOAuth) {
		return true
	}

	// Si no esta autenticado por Auth o por OAuth, redirige a la pagina de login
	router.navigate(['/auth/login'])
	return false
}

export const authLoginPageGuard: CanActivateFn = async (): Promise<boolean> => {
	const authService = inject(AuthService)
	const oauthService = inject(OAuthService)
	const router = inject(Router)

	const isAuthenticatedByAuth: boolean = await authService.isAuthenticated()
	const isAuthenticatedByOAuth: boolean = await oauthService.isAuthenticated()

	// Solo muestra la pagina de login si no esta autenticado por Auth o por OAuth
	if (!isAuthenticatedByAuth && !isAuthenticatedByOAuth) {
		return true
	}

	// Si esta autenticado por Auth o por OAuth, redirige a la pagina de inicio
	router.navigate(['/home'])
	return false
}
