import { Routes } from '@angular/router'
import { authGuard, authLoginPageGuard } from '@core/guards/auth.guard'

export const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('@shared/components/layouts/main.component').then((m) => m.MainLayoutComponent),
		loadChildren: () => import('@shared/components/layouts/main.router').then((m) => m.MainRoutes),
		canActivate: [authGuard],
  },
  {
    path: 'login',
    redirectTo: 'auth/login'
  },
	{
		path: 'auth',
		loadComponent: () => import('./modules/auth/presentation/auth.component').then((m) => m.AuthComponent),
		loadChildren: () => import('./modules/auth/presentation/auth.routes').then((m) => m.AuthRoutes),
		canActivate: [authLoginPageGuard],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
]
